import clsx from 'clsx';

export const StickyTop = ({
  belowHeader = true,
  children,
  containerClassName,
  ...props
}) => (
  <div
    className={clsx(
      'sticky z-[500] bg-white border-b',
      belowHeader ? 'top-[57px]' : 'top-0'
    )}
    {...props}
  >
    <div className={clsx('container py-3', containerClassName)}>{children}</div>
  </div>
);

export const StickyBottom = ({ children, containerClassName, ...props }) => (
  <div
    className="sticky bottom-0 z-[500] bg-white border-t"
    {...props}
    style={{
      paddingBottom: 'env(safe-area-inset-bottom)',
    }}
  >
    <div className={clsx('container py-4', containerClassName)}>{children}</div>
  </div>
);
