import React from 'react';

import dayjs from 'dayjs';

export const createDates = (daysInTheFuture = 30) =>
  [...Array(daysInTheFuture).keys()].map((d) => {
    const date = dayjs().add(d, 'days');

    return {
      label:
        d === 0
          ? 'Today'
          : d === 1
          ? 'Tomorrow'
          : dayjs(date).format('dddd, MMMM D, YYYY'),
      value: dayjs(date).format('YYYY-MM-DD'),
    };
  });

export default function DateSelect({
  value = '',
  daysInTheFuture,
  dates = createDates(daysInTheFuture),
  placeholder = 'Select date',
  ...props
}) {
  return (
    <select
      value={value}
      className="block w-full text-base bg-gray-100 border-none sm:text-sm rounded-md"
      {...props}
    >
      {placeholder && (
        <option disabled value="">
          {placeholder}
        </option>
      )}
      {dates.map(({ label, value, disabled }) => (
        <option key={value} value={value} disabled={disabled}>
          {label}
        </option>
      ))}
    </select>
  );
}
