import React from 'react';

import clsx from 'clsx';

import Spinner from './Spinner';

const BaseButton = React.forwardRef(
  (
    {
      href,
      isDisabled,
      isLoading,
      buttonClassName = 'bg-spoto text-white',
      spinnerClassName = 'text-white',
      fullWidth = false,
      children,
      ...props
    },
    ref
  ) => {
    const Component = href ? (isDisabled ? 'span' : 'a') : 'button';

    return (
      <Component
        ref={ref}
        href={href}
        disabled={isDisabled || isLoading}
        className={clsx(
          'flex items-center justify-center px-4 py-2 text-base leading-none rounded',
          fullWidth ? 'w-full' : 'w-auto',
          isDisabled && 'bg-opacity-75',
          buttonClassName
        )}
        {...props}
      >
        {isLoading ? (
          <React.Fragment>
            <Spinner className={clsx('-ml-1 mr-3', spinnerClassName)} />
          </React.Fragment>
        ) : (
          children
        )}
      </Component>
    );
  }
);
BaseButton.displayName = 'BaseButton';

export const PrimaryButton = React.forwardRef(
  ({ variant = 'default', ...props }, ref) => {
    const variants = {
      default: 'bg-spoto text-white font-bold h-12',
      danger: 'bg-red text-white font-bold h-12',
    };
    return (
      <BaseButton
        ref={ref}
        buttonClassName={clsx(variants[variant])}
        fullWidth
        {...props}
      />
    );
  }
);
PrimaryButton.displayName = 'PrimaryButton';

export const MutedButton = React.forwardRef((props, ref) => {
  return (
    <BaseButton
      ref={ref}
      buttonClassName="bg-gray-100 text-gray-500"
      spinnerClassName="text-gray-500"
      {...props}
    />
  );
});
MutedButton.displayName = 'MutedButton';

export const BlankButton = React.forwardRef(
  ({ variant = 'default', ...props }, ref) => {
    const variants = {
      default: 'text-gray-500',
      danger: 'text-red',
    };

    return (
      <BaseButton
        ref={ref}
        buttonClassName={clsx('bg-transparent font-normal', variants[variant])}
        spinnerClassName="text-gray-500"
        {...props}
      />
    );
  }
);
BlankButton.displayName = 'BlankButton';

export default PrimaryButton;
